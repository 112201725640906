export const FAQData = {
  title: "FAQs",
  description:
    " Got a question? No problem. We’ve covered the most common queries below.",
  accordionItems: [
    {
      heading:
        "How are we able to provide you with translation services on a 24/7 basis?",
      description:
        "Globalization and modern technology are wonderful, aren’t they? As our team works largely remotely, we are free to strategically recruit our translators, customer support staff and other team members from locations around the world. This means that we can follow the sun, with team members online in different time zones at different times.</br></br> As such, our online translation services are available 24/7. We work around the clock, so that whenever you need us, we’re right here, working on your translations and providing full support. Goodbye, old fashioned eight-hour day. Hello, seamless 24/7 service.",
    },
    {
      heading: "What does our one-year guarantee mean?",
      description:
        "Our one-year guarantee means you can enjoy peace of mind. It applies to every translation we provide. If there’s anything you’re unhappy with about your translation, for up to a year after we deliver it to you, just let us know and we’ll correct it right away. It’s part of our commitment to being a translation company that provides a thorough, attentive service every step of the way.",
    },
  ],
};
export const LanguageDetectorToolFaqData = [
  {
    id: 1,
    title:"Can the Language Detector identify multiple languages in a single text?",
    description:"Yes, the Language Detector can identify multiple languages within a single text, making it ideal for multilingual documents or mixed-language inputs.",
  },
  {
    id: 2,
    title:"Does the tool support file uploads, and what formats are accepted?",
    description:"Yes, the tool supports file uploads in formats like .docx, .txt, and .pdf, allowing you to analyze documents for language detection quickly.",
  },
  {
    id: 3,
    title:"How accurate is the tool in detecting less common languages?",
    description:"The tool is highly accurate in detecting both common and less common languages. However, for rare or mixed dialects, it is recommended to review the output for verification.",
  },


]
export const TextSummariseToolFaqData = [
  {
    id: 1,
    title:"How does the Summary Generator create concise summaries?",
    description:"The Summary Generator uses AI to analyze the main points and key ideas of the input text, condensing it into a shorter version while retaining the original meaning.",
  },
  {
    id: 2,
    title:"Can I upload documents or URLs for summarization?",
    description:"Yes, the tool supports both document uploads in formats like .docx, .txt, and .pdf, as well as inputting URLs to summarize content from websites or articles.",
  },
  {
    id: 3,
    title:"How accurate are the summaries generated by this tool?",
    description:"The summaries are highly accurate in capturing the essential ideas, but we recommend reviewing the output to ensure it aligns with your specific needs, especially for complex or technical content.",
  },


]
export const QaToolFaqData = [
  {
    id: 1,
    title:"What specific quality checks does the Translation Quality Assurance Tool perform?",
    description:"The Translation Quality Assurance Tool performs specific quality checks such as identifying grammatical errors, spelling mistakes, terminology inconsistencies, and formatting issues in the translated text.",
  },
  {
    id: 2,
    title:"Can the tool handle multiple language pairs and uploaded file formats?",
    description:"Yes, the Translation Quality Assurance Tool can handle multiple language pairs and supports file uploads in common formats like .docx, .txt, and .xliff, ensuring flexibility for different project needs.",
  },
  {
    id: 3,
    title:"How does the tool provide feedback on the translation quality?",
    description:"The Translation Quality Assurance Tool provides feedback on translation quality by generating a detailed quality score, highlighting issues related to accuracy, fluency, and formatting, and offering actionable insights for improvement.",
  },
 

]
export const AnonymizationToolFaqData = [
  {
    id: 1,
    title:"How does the Data Anonymization Tool protect sensitive information?",
    description:"The Data Anonymization Tool automatically removes or encrypts sensitive information from your text to ensure privacy and compliance with data protection regulations like GDPR.",
  },
  {
    id: 2,
    title:"Can the tool handle large datasets and different file formats?",
    description:"Yes, the tool can handle large datasets and supports multiple file formats such as .docx, .txt, and .csv, making it suitable for various types of documents and data.",
  },
  {
    id: 3,
    title:"What should I do if some sensitive information is not anonymized?",
    description:"If some sensitive information is not anonymized, you should manually review the output to ensure all sensitive details are adequately redacted before sharing or further processing the data.",
  },
 

]
export const GlossaryToolFaqData = [
  {
    id: 1,
    title:"How does the Bilingual Glossary Generator work?",
    description:"The Bilingual Glossary Generator extracts key terms from the provided source text and its translation to create a glossary. This glossary highlights terms that need consistent translation, while leaving proper names and street names unchanged.",
  },
  {
    id: 2,
    title:"Can I upload files for the glossary generation, and what formats are supported?",
    description:"Yes, you can upload files for glossary generation. The tool supports formats such as .docx, .txt, and .xliff, making it convenient for large and diverse projects.",
  },
  {
    id: 3,
    title:"How can the glossary generated by this tool improve my translation process?",
    description:"The glossary ensures consistency across your translations by providing a reference for key terms, reducing errors, and speeding up the overall translation workflow.",
  },
 

]
export const ConsistencyToolFaqData = [
  {
    id: 1,
    title:"How does the Translation Consistency Checker identify inconsistencies?",
    description:"The Translation Consistency Checker analyzes the source text and its translation to identify inconsistent usage of terms or phrases. It highlights the inconsistencies in the report and provides an overall consistency score for the translation.",
  },
  {
    id: 2,
    title:"Can the tool handle large texts or files, and what formats are supported?",
    description:"Yes, the tool supports large texts and files in formats such as .docx, .txt, and .xliff, making it suitable for reviewing extensive or complex translation projects.",
  },
  {
    id: 3,
    title:"How can this tool improve the quality of my translations?",
    description:"This tool improves translation quality by ensuring consistent terminology throughout your text, reducing errors, and making your translations more professional and reliable.",
  },
]
export const GuidanceToolFaqData = [
  {
    id: 1,
    title:"How does the Translation Guideline Adherence Checker help improve my translations?",
    description:"The Translation Guideline Adherence Checker helps improve your translations by comparing them to your provided guidelines, identifying any inconsistencies or deviations, and offering suggestions to ensure alignment with client expectations.",
  },
  {
    id: 2,
    title:"What types of guidelines can I include for evaluation?",
    description:"You can include various types of guidelines such as style guides, terminology preferences, formatting rules, and client-specific instructions to ensure the translation meets specific requirements.",
  },
  {
    id: 3,
    title:"Does the tool work with uploaded files, and in which formats?",
    description:"Yes, the tool supports file uploads for source text, translations, and guidelines in formats like .docx, .txt, and .pdf, allowing you to evaluate complex projects efficiently.",
  },
]
export const DifficultyCheckerToolFaqData = [
  {
    id: 1,
    title:"What does the Translation Difficulty Checker evaluate?",
    description:"The Translation Difficulty Checker evaluates the linguistic complexity of your source text, analyzing factors like sentence structure, vocabulary difficulty, and idiomatic expressions to recommend the most suitable translation method.",
  },
  {
    id: 2,
    title:"Can the tool suggest the right translation method for different text types?",
    description:"Yes, the Translation Difficulty Checker suggests the best translation method—whether raw machine translation, MTPE (Machine Translation Post-Editing), or human translation—based on the complexity of the input text.",
  },
  {
    id: 3,
    title:"Does the tool support file uploads, and in which formats?",
    description:"Yes, the tool supports file uploads in formats like .docx, .txt, and .pdf, enabling you to analyze and determine the complexity of lengthy or formatted texts efficiently.",
  },
]
export const TopicDetectionToolFaqData = [
  {
    id: 1,
    title:"What happens if my text covers multiple topics?",
    description:"If your text covers multiple topics, the Topic Detection Tool identifies the primary domain based on the overall context and key content, ensuring the most relevant subject area is detected for better translator assignment",
  },
  {
    id: 2,
    title:"Can the tool handle texts in different languages and file formats?",
    description:"Yes, the Topic Detection Tool can analyze texts in various languages and supports file uploads in formats like .docx, .txt, and .pdf for added convenience.",
  },
  {
    id: 3,
    title:"How does the tool help match texts with specialized translators?",
    description:"The Topic Detection Tool helps match texts with specialized translators by detecting the subject field, ensuring that the translator assigned has expertise in the relevant domain for higher quality translations.",
  },
]
export const PreTranslationToolFaqData = [
  {
    id: 1,
    title:"Can the Pre-Translation Toolkit help identify cultural nuances in the text?",
    description:"Absolutely. The Pre-Translation Toolkit identifies cultural nuances by analyzing contextual cues and flagging terms or phrases that may need localization adjustments to align with the target audiences expectations.",
  },
  {
    id: 2,
    title:"Is the Pre-Translation Toolkit suitable for all industries?",
    description:"Yes, its highly versatile. Whether your project is in legal, medical, technical, or marketing fields, the toolkit provides tailored insights to meet the unique needs of different industries.",
  },
  {
    id: 3,
    title:"Can the toolkit analyze large texts or files, and what formats are supported?",
    description:"Definitely. The toolkit is built to handle extensive texts and supports commonly used file formats such as .docx, .txt, and .pdf, making it convenient for large-scale projects.",
  },
]
export const ContextualToolFaqData = [
  {
    id: 1,
    title:"Can the Contextual Glossary Generator provide definitions for the identified terms?",
    description:"Yes, the Contextual Glossary Generator not only identifies key terms but also provides contextual definitions based on the input text, ensuring clarity and relevance.",
  },
  {
    id: 2,
    title:"Does the tool work with multilingual content?",
    description:"Absolutely. The tool supports multilingual content, allowing users to generate glossaries from texts written in multiple languages seamlessly.",
  },
  {
    id: 3,
    title:"Can I download the generated glossary for further use?",
    description:"Yes, the generated glossary is downloadable, making it easy to integrate into your workflows or share with your team for streamlined project management.",
  },
]
export const SourceTextCheckerToolFaqData = [
  {
    id: 1,
    title:"Can the Source Text Checker identify content that should not be translated?",
    description:"Yes, the Source Text Checker can identify elements like proper nouns, technical terms, trademarks, URLs, and wildcards that should remain untranslated to preserve the accuracy of the final translation.",
  },
  {
    id: 2,
    title:"Does the tool detect sensitive or culturally specific content?",
    description:"Absolutely. The tool flags sensitive content, such as gender-specific language, cultural nuances, currency, and measurement units, helping you adjust the text for global audiences.",
  },
  {
    id: 3,
    title:"What types of issues does the Source Text Checker address?",
    description:"The Source Text Checker addresses issues related to grammar, spelling, terminology, and formatting. It also highlights inconsistencies and ensures the source text is prepared for accurate translation.",
  },
]
export const StyleGuideToolFaqData = [
  {
    id: 1,
    title:"What kind of information does the Style Guide Generator include in the generated guide?",
    description:"The Style Guide Generator includes key elements such as tone of voice, formatting preferences, terminology usage, and language conventions to ensure consistency across your translation projects.",
  },
  {
    id: 2,
    title:"Can the generated style guide be downloaded for future use?",
    description:"Yes, the generated style guide is downloadable, allowing you to save it for future reference or share it with your team to maintain uniformity across projects.",
  },
  {
    id: 3,
    title:"Is the Style Guide Generator suitable for multiple industries?",
    description:"Absolutely. The tool is versatile and can cater to various industries, such as legal, marketing, technical, and more, by adapting its suggestions based on the content provided.",
  },
]