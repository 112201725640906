import React, { useState } from 'react'
import Arrowdown from "assets/arrowdown.svg";

const ToolFaq = ({QaToolFaqData}) => {
    const [selected, setSelected] = useState(QaToolFaqData[0].id);
    const [open, setOpen] = useState(true);
  
    const handleArrow = (id) => {
      if (id === selected) {
        setSelected(null);
        setOpen(false);
      } else {
        setSelected(id);
        setOpen(true);
      }
    };
  return (
    <div className='bg-white py-16'>
        <div className='max-w-6xl mx-auto px-4 sm:px-6'>
        <h2 className='text-[#0A2641] font-bold font-primary text-center text-[28px] leading-[33.82px]'>Frequently Asked Questions (FAQs)</h2>
 
        <div className="mt-12 flex items-center justify-center w-full flex-col">
              {QaToolFaqData.map(({  title, description, id }) => (
                <div
                  className="card w-full  flex justify-between mb-2 flex-col"
                  key={id}
                >
                  <div
                    className="flex px-4 py-5 bg-[#F0F5FF] rounded-lg justify-between cursor-pointer"
                    onClick={() => handleArrow(id)}
                  >
                    <div className="flex items-center">
                     
                      <h3
                        className={`text-base font-semibold font-opensans my-auto text-[#0A2641] `}
                      >
                        {title}
                      </h3>
                    </div>
                    <span
                      className={`text-2xl text-black cursor-pointer transform transition-transform ${
                        open && selected === id ? "rotate-180" : ""
                      }`}
                    >
                      <img src={Arrowdown} alt="" />
                    </span>
                  </div>
                  <div
                    className={`transition-all ease-in-out delay-150 pb-4 duration-300 p-4 ${
                      selected === id ? "block" : "hidden"
                    }`}
                  >
                    {open ? (
                      <p className="text-[#424242] font-opensans text-base font-normal leading-6">
                        {description}
                      </p>
                    ) : null}
                  </div>
                </div>
              ))}
            </div>
        </div>
   
      
    </div>
  )
}

export default ToolFaq
