import QualityIssuesOutput from "components/PreTranslation/QualityIssuesOutput";
import React from "react";




const SourceOutput = ({ result }) => {
  
 return (
    <div className="w-full max-w-7xl mx-auto px-4 font-opensans py-12 lg:py-24">
      <div className="flex justify-between items-center pb-3 border-b border-[#C7C7C7]">
        <p className="text-lg text-gray26 font-bold font-opensans">
          Results
        </p>
        <div className="flex items-center gap-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="15"
            viewBox="0 0 16 15"
            fill="none"
          >
            <path
              d="M15.7658 6.93435L9.3657 0.534281C9.25382 0.422432 9.11128 0.346266 8.95612 0.315412C8.80096 0.284557 8.64013 0.3004 8.49397 0.360937C8.34781 0.421473 8.22287 0.523986 8.13496 0.655515C8.04706 0.787045 8.00012 0.941685 8.00009 1.09989V3.93592C5.8147 4.13815 3.78342 5.14862 2.30378 6.76957C0.824132 8.39052 0.00261741 10.5053 0 12.7V13.9C0.000126286 14.0661 0.0519283 14.228 0.148221 14.3633C0.244513 14.4986 0.380523 14.6006 0.537386 14.6551C0.694249 14.7096 0.864189 14.714 1.02363 14.6675C1.18308 14.6211 1.32413 14.5262 1.42722 14.396C2.2111 13.4641 3.17279 12.6977 4.25612 12.1415C5.33946 11.5854 6.52274 11.2506 7.73688 11.1568C7.77688 11.152 7.87688 11.144 8.00009 11.136V13.9C8.00012 14.0582 8.04706 14.2129 8.13496 14.3444C8.22287 14.4759 8.34781 14.5784 8.49397 14.639C8.64013 14.6995 8.80096 14.7154 8.95612 14.6845C9.11128 14.6536 9.25382 14.5775 9.3657 14.4656L15.7658 8.06556C15.9157 7.91554 16 7.71209 16 7.49996C16 7.28782 15.9157 7.08437 15.7658 6.93435ZM9.6001 11.9688V10.3C9.6001 10.0878 9.51582 9.88432 9.36579 9.73429C9.21575 9.58426 9.01227 9.49998 8.80009 9.49998C8.59609 9.49998 7.76328 9.53998 7.55048 9.56798C5.39429 9.76642 3.34185 10.5856 1.64162 11.9264C1.83461 10.1619 2.67166 8.53064 3.99249 7.34488C5.31331 6.15912 7.0251 5.50221 8.80009 5.49993C9.01227 5.49993 9.21575 5.41565 9.36579 5.26562C9.51582 5.11559 9.6001 4.9121 9.6001 4.69993V3.03111L14.069 7.49996L9.6001 11.9688Z"
              fill="#5B93FF"
            />
          </svg>
          <span className="text-sm font-opensans text-[#5B93FF] font-semibold">
            Share
          </span>
        </div>
      </div>
      <QualityIssuesOutput
            header="Quality Issues Identified"
            output={ result?.result}
          />
      {/* <div className="grid lg:grid-cols-2 gap-4">
        <div></div>
       <div></div>
      </div> */}
    </div>
  );
};

export default SourceOutput;
